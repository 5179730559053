<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new email template</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new email template</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onFormSubmit"> <!-- @keyup.enter.native="create" -->
                            <!-- <template v-if="website">
                                <p>This form will be linked to the website
                                    <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: website.id } }">{{ website.label }}</router-link>.
                                    You can update the website content from this form.</p>
                            </template> -->
                            <!-- <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <p v-bind="attrs" v-on="on">What is a form alias? <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text text-lighten-2"/></p>
                                </template>
                                <p>When you create a form, we generate a unique identifier for it. When a form is attached to a server, it is generally accessible at <span class="text-no-wrap">/mnt/form/&lt;id&gt;</span>. When you access the storage form via file server, we create a convenient link from your home directory to the form mount point at <span class="text-no-wrap">~/&lt;account-alias&gt;/&lt;form-alias&gt;</span>. This makes it easier to recognize and access forms that you have access to. A form alias must be unique within the account.</p>
                            </v-tooltip>
                            <v-text-field
                                ref="formAliasInput"
                                v-model="newFormAlias"
                                label="Form alias"
                                :rules="newFormAliasRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                hint="The directory name to use for the form (you can change this later)"
                                type="text"
                                outlined
                            >
                            </v-text-field> -->
                            <p>
                                The "label" is how the email template will appear in your account. It must be unique so you can identify this email template.
                                You can change it at any time.
                                It is <span class="font-weight-bold">private</span> and will NOT be shown to users.
                            </p>
                            <v-text-field
                                ref="emailTemplateLabelInput"
                                v-model="newEmailTemplateLabel"
                                label="Email template label"
                                :rules="newEmailTemplateLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                            >
                            </v-text-field>

                            <!-- <v-select outlined :items="newFormAllocatedSizeChoices" v-model="newFormAllocatedGb" color="teal darken-2" label="Form size" required hint="Storage space to allocate for this form (you can change this later)"/> -->

                            <!-- TODO: optional, select "zone" where it can be hosted (doesn't affect who can access it), use account setting if specified, otherwise default is anywhere -->
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onFormSubmit" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { isValidFormAlias, compact } from '@/sdk/input';
import { mapState } from 'vuex';

export default {
    data: () => ({
        error: null,
        website: null,
        // create form
        createEmailTemplateForm: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newEmailTemplateLabel: null,
        newEmailTemplateLabelRules: [
            (v) => !!v || 'Form label is required',
        ],
        // newFormAllocatedGb: 1,
        // newFormAllocatedSizeChoices: [
        //     { text: '1 GB', value: 1 },
        //     { text: '5 GB', value: 5 },
        // ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            // TODO: check that form alias is not too similar to existing form alias
            return /* typeof this.newFormAlias === 'string' && this.newFormAlias.trim().length > 0 && */ typeof this.newEmailTemplateLabel === 'string' && this.newEmailTemplateLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newEmailTemplateLabel === null || this.newEmailTemplateLabel.length === 0) {
        //                 this.newEmailTemplateLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        onFormSubmit() {
            if (!this.isFormComplete) {
                return;
            }
            this.createEmailTemplate();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createEmailTemplate() {
            try {
                this.error = false;
                this.$store.commit('loading', { createEmailTemplate: true });
                console.log('createEmailTemplate');
                const request = {
                    // alias: this.newFormAlias,
                    label: this.newEmailTemplateLabel,
                    // type: 'f', // f=form, p=poll, s=survey
                    // allocated_gb: this.newFormAllocatedGb,
                };
                // if (this.website) {
                //     request.website_id = this.website.id;
                // }
                const response = await this.$client.account(this.$route.params.accountId).emailTemplate.create(request);
                console.log('createEmailTemplate response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-email-template', params: { accountId: this.$route.params.accountId, emailTemplateId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email template' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email template' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create email template', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email template' });
            } finally {
                this.$store.commit('loading', { createEmailTemplate: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // if (this.$route.query.website_id) {
        //     this.loadWebsite(this.$route.query.website_id);
        // }
    },
};
</script>
